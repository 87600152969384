<template>
  <div :class="prefixCls" :style="{height: 'auto'}">
<!--  <div :class="prefixCls" :style="{height: windowHeight}">-->
<!--    <swiper-->
<!--      :class="`${prefixCls}-swiper`"-->
<!--      :options="swiperOption"-->
<!--      v-if="carouselData.length && windowHeight"-->
<!--    >-->
<!--      <swiper-slide v-for="item in carouselData" :key="item.id" :class="`${prefixCls}-swiper-slide`">-->
<!--        <img :src="item.pic" :alt="item.name" >-->
<!--      </swiper-slide>-->
<!--      <div class="swiper-pagination" slot="pagination"></div>-->
<!--    </swiper>-->
    <el-carousel class="my-carousel" :height="`${height}px`" :autoplay="false">
      <el-carousel-item v-for="item in carouselData" :key="item.id">
        <img :src="item.pic" :style="{ height: `${height}px` }" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'componentsCarouselIndex',
  props: {
    carouselData: {
      type: Array,
      default: () => {
        return []
      }
    },
    field: {
      type: String,
      default: 'picturePath'
    }
  },
  components: {
    // swiper,
    // swiperSlide
  },
  data () {
    return {
      prefixCls: this.$prefix + '-components-carousel',
      active: '1',
      swiperObj: undefined,
      windowHeight: 0,
      imgList: [],
      height: null,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        effect: 'fade',
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    this.setPreviewList()
  },
  mounted () {
    this.height = window.innerWidth / (1920 / 600)
    // if (this.height === 'null') {
    //   const clientHeight = this.calcWindowHeight()
    //   if (clientHeight < 1200) {
    //     this.windowHeight = clientHeight - 80 + 'px'
    //   } else {
    //     this.windowHeight = 1200 + 'px'
    //   }
    // } else {
    //   // 支持百分比模式
    //   if (this.height.indexOf('%') > 0) {
    //     const clientHeight = this.calcWindowHeight()
    //     const num = this.height.replace('%', '') / 100
    //     this.windowHeight = clientHeight * num + 'px'
    //   } else {
    //     this.windowHeight = this.height
    //   }
    // }
  },
  watch: {
    carouselData () {
      this.setPreviewList()
    }
  },
  methods: {
    onSwiper (swiper) {

    },
    calcWindowHeight () {
      let clientHeight = 0
      if (document.documentElement && document.documentElement.clientHeight) {
        clientHeight = document.documentElement.clientHeight
      } else if (document.body) {
        clientHeight = document.body.clientHeight
      }
      return clientHeight
    },
    onSlideChange  () {

    },
    setPreviewList () {
      if (this.carouselData) {
        this.imgList = this.carouselData.map(item => {
          return item.pic
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -components-carousel;
  .#{$prefixCls}{
      width: 100%;
      //max-height: $content-width;
      .my-carousel {
        .el-carousel__container {
          height: auto !important;
          overflow: hidden;
          .el-carousel__item {
            img {
              width: 100%;
            }
          }
        }
      }
  }
</style>
