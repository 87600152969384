import request from '@/utils/axios'

// 获取栏目列表
export function getCannelList () {
  return request({
    url: '/rest/siteChannel/getCannelList',
    method: 'post'
  })
}

// 网站-根据栏目id查询-banner表列表
export function getBannerByChannelId (id) {
  return request({
    url: '/rest/siteBanner/getBannerByChannelId',
    method: 'post',
    data: { id }
  })
}

export function siteGoodPage () {
  return request({
    url: '/rest/siteGood/getPage',
    method: 'post'
  })
}

export function getAllList () {
  return request({
    url: '/rest/companyHistory/getAllList',
    method: 'post'
  })
}

export function getList () {
  return request({
    url: '/rest/siteGoodCategories/getList',
    method: 'post'
  })
}

// 查询网站新闻表列表
export function siteNewsPage (params, data) {
  return request({
    url: '/rest/siteNews/getPage',
    method: 'post',
    params,
    data
  })
}

export function siteNewsDetail (id) {
  return request({
    url: '/rest/siteNews/getDetail',
    method: 'post',
    data: { id }
  })
}

export function siteGoodDetail (id) {
  return request({
    url: '/rest/siteGood/getDetail',
    method: 'post',
    data: { id }
  })
}

export function getChairmanSayInfo () {
  return request({
    url: '/rest/siteChairmanSay/getChairmanSayInfo',
    method: 'post'
  })
}

export function siteCorporateCultureList () {
  return request({
    url: '/rest/siteCorporateCulture/getList',
    method: 'post'
  })
}

export function sitezxnsPage () {
  return request({
    url: '/rest/sitezxns/getPage',
    method: 'post',
    data: { channelId: 0 }
  })
}

export function siteYfcxList (data) {
  return request({
    url: '/rest/siteYfcx/getList',
    method: 'post',
    data
  })
}

export function siteRecruitmentPage () {
  return request({
    url: '/rest/siteRecruitment/getPage',
    method: 'post'
  })
}

export function getPage ({ data, params }) {
  return request({
    url: '/rest/siteTNotice/getPage',
    method: 'post',
    data,
    params
  })
}

export function getDetail (id) {
  return request({
    url: '/rest/siteTNotice/getDetail',
    method: 'post',
    data: { id }
  })
}
