export const carouselMixin = {
  data () {
    return {
      carouselHeight: 0
    }
  },
  mounted () {
    // this.carouselHeight = document.body.clientHeight * 0.7
    this.carouselHeight = 1000
  },
  methods: {
    nextDomById (id) {
      // document.querySelector(`#${id}`).scrollIntoView({
      //   behavior: 'smooth'
      // })
    }
  }
}
